.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #2079bf;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(25, 93, 147, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  font-family: "Roboto", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.3125rem + 1.5 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.3125rem;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.3125rem + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 2.8125rem;
  }
}
.startpage h1,
.startpage .style_h1 {
  color: #000;
}
h2,
.style_h2 {
  letter-spacing: calc((1.25rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.25rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.25rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 1.875rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.125rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.125rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.125rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.25rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1.0625rem + 0.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.0625rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1.0625rem + 0.0625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.125rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.75rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.75rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.75rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 0.875rem;
  }
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #195D93;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(25, 93, 147, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #195D93;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(25, 93, 147, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 400;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.125rem solid #C2C2C2;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 400;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #C2C2C2;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #EFEFEF;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #195D93;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.125rem solid #C2C2C2;
  margin: 2.25em 0 0.75em;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.125rem solid #C2C2C2;
  font-weight: 400;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.125rem solid #C2C2C2;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 300;
  letter-spacing: calc((0.875rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #144a74;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #144a74;
  color: #fff;
  display: none;
  font-weight: 400;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.25rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.25rem;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.25rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.875rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.125rem solid #e5e5e5;
  border-radius: 0;
  color: #000;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #195D93;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #195D93;
  color: #000;
}
.input:focus,
.textarea:focus {
  border-color: #195D93;
  color: #000;
}
.input {
  height: 2.75rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #195D93;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #195D93;
  color: #000;
}
.control input:focus ~ .indicator {
  border-color: #195D93;
  color: #000;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.125rem solid #e5e5e5;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.125rem solid #e5e5e5;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #195D93;
}
.select.error.focus {
  border-color: #195D93;
  color: #000;
}
.select.focus {
  border-color: #195D93;
  color: #000;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
  margin: 0;
  min-height: 2.75rem;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
select option {
  min-height: 2.5rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.5rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 3.125rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select:focus::-ms-value {
  background: none;
  color: #000;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(25, 93, 147, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(25, 93, 147, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(25, 93, 147, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(25, 93, 147, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact abbr {
    min-width: 1.25rem;
  }
  .contact a {
    color: #000;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.125rem solid #C2C2C2;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #195D93;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #195D93;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #195D93;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #195D93;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .author_hor {
    display: none!important;
    fill: #999999;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    width: 4.6875rem;
    z-index: 15;
  }
  .button {
    border-radius: 0;
    padding: 0 2.25em;
    background: #fff;
    border: 0.125rem solid #195D93;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    outline: 0;
    background: #80A5C3;
    border-color: #80A5C3;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #195D93;
    border-color: #195D93;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    background: #124167;
    border: 0.125rem solid #124167;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.75em;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .company_logo {
    width: 9.375rem;
    display: block;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #195D93;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #195D93;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .atikon_logo {
    height: 100%;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #195D93;
    border-top: 0.125rem solid #fff;
    bottom: 0;
    color: #fff;
    font-size: 85%;
    left: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner .text_wrapper {
    margin-right: 3em;
  }
  .cookie_banner .text_wrapper .zusatz,
  .cookie_banner .text_wrapper .headline {
    display: none;
  }
  .cookie_banner .link_wrapper {
    margin-top: 1.5em;
  }
  .cookie_banner p {
    margin: 0;
  }
  .cookie_banner a {
    color: #fff;
  }
  .cookie_banner .button {
    margin-bottom: 0.375em;
    background: #80A5C3;
    border: 0.125rem solid #80A5C3;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .cookie_banner .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .cookie_banner .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .cookie_banner .button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .cookie_banner .button .icon + .text {
    padding-left: 0.5em;
  }
  .cookie_banner .headline {
    color: #fff;
    margin-bottom: 0.375em;
  }
  .cookie_banner .close {
    cursor: pointer;
    margin-right: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.5rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.3em;
  }
  .contact_form .form_section {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .contact_form .form_section .entry {
    flex-basis: 48%;
    max-width: 48%;
  }
  .form_disclaimer {
    background: #EFEFEF;
    border-radius: 0;
    color: #000;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
    text-align: center;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #195D93;
  }
  .file .file_name {
    line-height: 2.5rem;
    background: #fff;
    border: 0.125rem solid #e5e5e5;
    border-radius: 0 0 0 0;
    color: #000;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #fff;
    border: 0.125rem solid #195D93;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .file .file_button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .file .file_button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    background: #80A5C3;
    border-color: #80A5C3;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #195D93;
    border-color: #195D93;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    background: #124167;
    border: 0.125rem solid #124167;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    height: 2.75rem;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #EFEFEF;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #124167;
    border-color: #124167;
    color: #fff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.375rem rgba(25, 93, 147, 0.2);
    z-index: 15;
    background: #80A5C3;
    border-color: #80A5C3;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #C2C2C2;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #144a74;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.125rem solid #C2C2C2;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.125rem solid #C2C2C2;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  footer {
    background: #195D93;
    margin-top: 3em;
  }
  footer .footer_company_logo {
    background: #fff;
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.25);
    display: block;
    margin: 0 auto;
    padding: 1.5em;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 9.375rem;
  }
  footer .footer_address .contact_wrapper {
    margin-top: -1.5em;
  }
  footer .contact_wrapper {
    color: #fff;
    display: block;
    text-align: center;
  }
  footer .contact_wrapper p {
    margin-right: 0;
  }
  footer .contact_wrapper a {
    color: #fff;
    text-decoration: none;
  }
  footer .contact_wrapper a:hover {
    text-decoration: underline;
  }
  footer .contact {
    display: block;
  }
  footer .related_links {
    margin-top: 2.1em;
    padding-bottom: 2.25em;
  }
  footer .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  footer .related_links ul li:nth-child(2) {
    position: relative;
  }
  footer .related_links ul li:nth-child(2):before,
  footer .related_links ul li:nth-child(2):after {
    color: #fff;
    content: "|";
    display: inline-block;
    margin: 0 0.375em;
  }
  footer .related_links a {
    color: #fff;
    text-decoration: none;
  }
  footer .related_links a:hover {
    text-decoration: underline;
  }
  .header_top_bar {
    position: relative;
  }
  .header_top_bar:before,
  .header_top_bar:after {
    background: #fff;
    border-bottom: 0.125rem solid #C2C2C2;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .header_top_bar:before {
    left: 100%;
  }
  .header_top_bar:after {
    right: 100%;
  }
  .header_top_bar .call_mail {
    left: 0;
    position: absolute;
    top: 1.5rem;
    z-index: 60;
  }
  .header_top_bar .call_mail a:first-child {
    margin-bottom: 1.25rem;
  }
  .header_top_bar .call_mail a {
    -webkit-box-align: center;
            align-items: center;
    color: #000;
    display: -webkit-box;
    display: flex;
    font-weight: 400;
    text-decoration: none;
  }
  .header_top_bar .call_mail a .text {
    display: none;
  }
  .header_top_bar .call_mail a:hover .text:before,
  .header_top_bar .call_mail a:focus .text:before {
    right: 0;
  }
  .header_top_bar .call_mail a:first-child {
    margin-right: 4.5em;
  }
  .header_top_bar .call_mail .icon {
    display: inline-block;
    height: 1.375rem;
    margin-right: 0.75em;
    width: 1.375rem;
  }
  .header_logo_nav {
    background: #fff;
    padding: 1em 0;
    position: relative;
    height: 7.5em;
  }
  .header_logo_nav .company_logo {
    left: 50%;
    margin-top: 0.5em;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 50;
  }
  .header_logo_nav .container {
    height: 100%;
  }
  .header_picture {
    box-shadow: 0.1875rem 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.25);
    margin-right: calc(-100vw + 100%);
    position: relative;
    z-index: 60;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-top: 2.55em;
    position: relative;
    width: 100%;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:hover > a,
  .dropdown_menu > ul li.hover > a {
    border-bottom: 0.1875rem solid #80A5C3;
    color: #195D93;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    visibility: visible;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 0.75em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
    margin-left: -0.75em;
  }
  .dropdown_menu > ul > li:nth-child(2) {
    margin-right: 30%;
  }
  .dropdown_menu > ul > li:nth-child(3) ul,
  .dropdown_menu > ul > li:last-child ul {
    right: 0;
  }
  .dropdown_menu > ul > li:nth-child(3) ul a,
  .dropdown_menu > ul > li:last-child ul a {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    border-bottom: 0.1875rem solid #fff;
    color: #000;
    display: -webkit-box;
    display: flex;
    font-weight: 400;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.75em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 1rem;
  }
  .dropdown_menu > ul > li > a.active {
    border-bottom: 0.1875rem solid #80A5C3;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.15);
    min-width: 13.75rem;
    padding: 0;
    position: absolute;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    border-bottom: 0.125rem solid #e5e5e5;
    color: #000;
    display: -webkit-box;
    display: flex;
    font-weight: 400;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0.375em 0.75em;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:hover {
    border-bottom: 0.125rem solid #e5e5e5;
    color: #195D93;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    border-bottom: 0.125rem solid #e5e5e5;
    color: #195D93;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.75em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    background: #f2f2f2;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin: 0.75em 0;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .download_overview .touch_link .title {
    text-decoration: none;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .touch_link .title_wrapper small {
    color: #195D93;
    font-size: 70%;
  }
  .download_overview .touch_link:hover {
    background: #e5e5e5;
  }
  .download_overview .touch_link .description {
    padding: 1.5em;
    width: 100%;
  }
  .download_overview .icon {
    display: none;
  }
  .u_online_overview .touch_link {
    margin: 1.5em 0;
  }
  .u_online_overview .touch_link .title {
    display: inline-block;
    position: relative;
    text-decoration: none !important;
  }
  .u_online_overview .touch_link .title:after {
    background: #195D93;
    bottom: -0.25rem;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
  }
  .u_online_overview .touch_link:hover .title:after {
    right: 0;
  }
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #000;
    font-family: "Roboto", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #000;
    font-weight: 400;
  }
  .u_online_text {
    fill: #000;
    font-family: "Roboto", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #000;
  }
  .u_online_warning_icon {
    fill: #000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #000;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #195D93;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #195D93;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #195D93;
  }
  .u_online_button:hover .u_online_icon {
    fill: #000;
  }
  .u_online_lines {
    fill: #195D93;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow ul {
    list-style: none !important;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #195D93;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #8caec9;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.75em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.75em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.5em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #195D93;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #EFEFEF;
    display: table;
    margin: 0 auto 0.75em;
    padding: 1.5em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.25rem;
    border-color: #195D93;
    border-left-width: 0.125rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.125rem;
    color: #000;
    display: block;
    font-size: 130%;
    font-weight: 500;
    margin-right: 0.375rem;
    padding: 0 1.5em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
    line-height: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #195D93;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    position: relative;
    -webkit-transition: background-color 300ms;
    transition: background-color 300ms;
    vertical-align: middle;
    z-index: 1;
    height: 2.75rem;
    width: 2.75rem;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #000;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #000;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #124167;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #124167;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.5rem solid #195D93;
    border-radius: 50%;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(25, 93, 147, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .content_bg_image_container {
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 0;
  }
  .content_bg_image_container > .container {
    width: 100%;
  }
  .content_bg_image_container.center {
    background-position-x: center;
  }
  .content_bg_image_container.left {
    background-position-x: left;
  }
  .content_bg_image_container.right {
    background-position-x: right;
  }
  .content_bg_image_container.middle {
    background-position-y: center;
  }
  .content_bg_image_container.top {
    background-position-y: top;
  }
  .content_bg_image_container.bottom {
    background-position-y: bottom;
  }
  .content_bg_image_container.show_overlay::after {
    background: rgba(25, 93, 147, 0.8);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .content_bg_image_container.fixed {
    background-attachment: fixed;
  }
  html.ios .content_bg_image_container.fixed,
  html.mobile .content_bg_image_container.fixed {
    background-attachment: scroll;
  }
  .content_bg_image_container.full_width {
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
  .toggle_offcanvas_menu {
    display: block;
    margin-top: 0;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    font-size: 112.5%;
    font-weight: 400;
    height: 2.75rem;
    -webkit-box-pack: start;
            justify-content: flex-start;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-left: 0.75em;
    margin-right: 0.375em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #000;
    border-radius: 0.3125rem;
    display: block;
    height: 0.125rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.375rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.375rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #195D93;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    display: block;
    min-height: 100%;
    opacity: 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    background: #195D93;
    border-bottom: 0.125rem solid #fff;
    color: #fff;
    display: block;
    font-size: 112.5%;
    font-weight: 400;
    line-height: 2.75rem;
    padding: 0 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background: #80A5C3;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    background: #fff;
    border-bottom: 0.125rem solid #EFEFEF;
    color: #000;
    display: block;
    padding: 0.5em 1.5em 0.5em 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background: #80A5C3;
    color: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.125rem solid #C2C2C2;
    border-top: 0.125rem solid #C2C2C2;
  }
  .page_wrapper {
    margin: 0 auto;
    max-width: 120rem;
    overflow-x: hidden;
  }
  .image_wrapper {
    margin-bottom: 3em;
  }
  .image_wrapper .sitetitle_wrapper {
    background: #195D93;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
  .image_wrapper .sitetitle_wrapper:before,
  .image_wrapper .sitetitle_wrapper:after {
    background: #195D93;
    content: "";
    position: absolute;
  }
  .image_wrapper .sitetitle_wrapper:after {
    bottom: 0;
    height: 500%;
    right: 100%;
    width: 100%;
    z-index: -1;
  }
  .image_wrapper .sitetitle_wrapper .text_wrapper {
    margin-bottom: 1.5em;
    margin-right: 3.75em;
    margin-top: 1.5em;
  }
  .image_wrapper .sitetitle_wrapper .slogan {
    font-size: 240%;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1.1;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    max-width: 95%;
  }
  .startpage_u_online {
    max-width: 31.25rem;
    padding-top: 6em;
  }
  .startpage_u_online p,
  .startpage_u_online h2 {
    color: #fff;
  }
  .startpage_u_online h2 {
    padding-top: 0;
  }
  .startpage_u_online_button {
    background: #fff;
    border: 0.125rem solid #fff;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 6em;
    margin-top: 2.25em;
  }
  .startpage_u_online_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .startpage_u_online_button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  .startpage_u_online_button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  .startpage_u_online_button .icon + .text {
    padding-left: 0.5em;
  }
  .overview .touch_link {
    background: #f2f2f2;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin: 1.5em 0;
    padding: 1.5em;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .overview .touch_link .title {
    text-decoration: none;
  }
  .overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .overview .touch_link .title_wrapper small {
    color: #195D93;
    font-size: 70%;
  }
  .overview .touch_link:hover {
    background: #e5e5e5;
  }
  .overview .touch_link .description {
    padding: 1.5em;
    width: 100%;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #000;
    font-family: "Roboto", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #000;
    font-weight: 400;
  }
  .arbeitnehmer_online_text {
    fill: #000;
    font-family: "Roboto", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #000;
  }
  .arbeitnehmer_online_icon {
    fill: #195D93;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #195D93;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #195D93;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #000;
  }
  .arbeitnehmer_online_lines {
    fill: #195D93;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .infolist_overview .entry {
    margin: 1.5em 0;
  }
  .infolist_overview .entry .title {
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  .infolist_overview .entry .title:after {
    background: #195D93;
    bottom: -0.25rem;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
  }
  .infolist_overview .entry p {
    margin-bottom: 0;
  }
  .infolist_overview .entry:hover .title:after {
    right: 0;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #195D93;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #EFEFEF;
    border-radius: 0;
    color: #000;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #EFEFEF;
    border-radius: 0;
    color: #000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 400;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.125rem solid #C2C2C2;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .onlinetools_popup_overview .touch_link.entry {
    margin: 2.25em 0;
  }
  .onlinetools_popup_overview .touch_link .title {
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  .onlinetools_popup_overview .touch_link .title:after {
    background: #195D93;
    bottom: -0.25rem;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
  }
  .onlinetools_popup_overview .touch_link:hover .title:after {
    right: 0;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #195D93;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.15);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #EFEFEF;
    cursor: move;
    position: relative;
    width: 100%;
    height: 50vh;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(239, 239, 239, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #195D93;
    border-radius: 0;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 400;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #80A5C3;
    border: 0.125rem solid #80A5C3;
    box-shadow: 0 0 0 0.375rem rgba(25, 93, 147, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #195D93;
    border: 0.125rem solid #195D93;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 3rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #EFEFEF;
    border-radius: 0;
    color: #000;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .toggle_search_box {
    height: 2.75rem;
    margin-left: auto;
    position: relative;
    width: 2.75rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #195D93;
  }
  .toggle_search_box a {
    fill: #000;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 2.75rem;
    margin-left: auto;
    padding: 0.75em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.75rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.75em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #fff;
    border: 0.125rem solid #195D93;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.5em;
    top: 1.5em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #80A5C3;
    border-color: #80A5C3;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #195D93;
    border-color: #195D93;
    color: #fff;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #fff;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #C2C2C2;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    border: 0.125rem solid #EFEFEF;
    display: none;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    height: 2.5rem;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 0.125rem solid #fff;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
  }
  form[role="search"] button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.25rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.25rem;
  }
  form[role="search"] button .text {
    font-weight: 300;
    padding: 0.375em 0;
    text-transform: uppercase;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.5em;
  }
  form[role="search"] button[disabled] {
    background-color: #fff;
    border-color: #fff;
    color: #808080;
  }
  form[role="search"] button[disabled] .icon {
    fill: #000;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    position: relative;
    z-index: 10;
    background: #80A5C3;
    border-color: #80A5C3;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: 2.5rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.125rem solid #fff;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #e5e5e5 inset;
    color: #000;
    flex-basis: 100%;
    height: 2.5rem;
    margin: 0;
    min-width: 13.125rem;
    padding: 0 0.75em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #195D93;
    color: #000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.75em;
    padding-top: 0.375em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .steuernews_newsletter_subscribe {
    background: #EFEFEF;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .team_overview .entry {
    border: 0.125rem solid #80A5C3;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 2.25em;
    padding: 1.5em;
    position: relative;
  }
  .team_overview .entry.even {
    border: 0.125rem solid #195D93;
  }
  .team_overview .entry.even .family_name {
    border-bottom: 0.125rem solid #195D93;
  }
  .team_overview .entry.even .job_title {
    color: #144a74;
  }
  .team_overview .entry .first_name {
    display: block;
    font-weight: 300;
    line-height: 1;
    text-align: right;
    letter-spacing: calc((1.125rem + 0.125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.125rem;
  }
  .team_overview .entry .family_name {
    border-bottom: 0.125rem solid #80A5C3;
    display: block;
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
    text-align: right;
    letter-spacing: calc((1.25rem + 0.625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.25rem;
  }
  .team_overview .entry .job_title {
    color: #6894b8;
    text-align: right;
    text-transform: uppercase;
  }
  .team_overview .entry .contact_data {
    margin-bottom: 0;
    margin-top: 1.5em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    display: -webkit-box;
    display: flex;
    margin: 0 1.5em 0.75em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #195D93;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_article_slider {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .steuernews_article_slider .slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    left: 0;
    position: absolute;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    position: relative;
  }
  .steuernews_article_slider .slide .image {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    max-width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75em 0;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    word-wrap: break-word;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .control_slides {
    -webkit-box-align: center;
            align-items: center;
    background: #EFEFEF;
    display: -webkit-box;
    display: flex;
    fill: #000;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 30%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: none;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #195D93;
    outline: 0;
  }
  .steuernews_article_slider .control_slides .icon {
    height: 1.75rem;
    width: 1.75rem;
  }
  .steuernews_article_slider .prev_slide {
    left: -0.0625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: -0.0625rem;
  }
  .sitemap ul a {
    color: #000;
  }
  .teaser_overview {
    margin-bottom: 1.5em;
  }
  .teaser_overview .teaser {
    text-align: center;
  }
  .teaser_overview .teaser .button {
    margin-bottom: 2.25em;
    margin-top: 1.95em;
  }
  .teaser_overview .teaser .title {
    padding-top: 1.8em;
  }
  aside .teaser_overview .teaser {
    background: #195D93;
    color: #fff;
    padding: 0.75em 2.25em;
    margin-bottom: 1.5em;
  }
  aside .teaser_overview .teaser .title {
    color: #fff;
    letter-spacing: calc((1.0625rem + 0.0625 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.0625rem;
  }
  aside .teaser_overview .teaser .button:hover {
    background: #195D93;
    border-color: #80A5C3;
    color: #fff;
  }
  aside .teaser_overview .teaser .button:hover .icon {
    fill: #fff;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0.75em 0;
  }
  .video_overview .image img,
  .video_infolists_overview .image img,
  .video_infolist_overview .image img,
  .video_tutorial_overview .image img,
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #195D93;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #195D93;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #195D93;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #EFEFEF;
    border: 0.125rem solid #C2C2C2;
    border-radius: 0;
    bottom: 100%;
    color: #000;
    font-size: 85%;
    font-weight: 300;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #EFEFEF;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #C2C2C2;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview.startpage_news_overview {
    margin-top: 1.5em;
  }
  .steuernews_preview .headline {
    display: block;
    text-align: center;
    letter-spacing: calc((1.25rem + 0.3125 * (100vw - 20rem) / (73.75)) / 1000 * 0);
    font-size: 1.25rem;
  }
  .steuernews_preview .overview_title {
    margin: 0 auto 0.75em;
    max-width: 50rem;
    padding-top: 0.375em;
    text-align: center;
  }
  .steuernews_preview .entry {
    color: #000;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
    position: relative;
    -webkit-transition: 300ms;
    transition: 300ms;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
  .steuernews_preview .entry:after {
    background: #fff;
    content: "";
    height: 100%;
    left: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-95%);
            transform: translateX(-95%);
    width: 1.5em;
  }
  .steuernews_preview .entry:hover {
    background: #195D93;
  }
  .steuernews_preview .entry:hover .title {
    color: #fff;
  }
  .steuernews_preview .entry:hover .fake_button .icon {
    fill: #fff;
  }
  .steuernews_preview .entry:hover .fake_button .text {
    color: #fff;
  }
  .steuernews_preview .entry:hover .teaser_text p {
    color: #fff;
  }
  .steuernews_preview .entry .title {
    color: #000;
    text-decoration: none;
    -webkit-transition: 300ms;
    transition: 300ms;
    word-wrap: break-word;
  }
  .steuernews_preview .entry .fake_button {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin-top: 1.5em;
  }
  .steuernews_preview .entry .fake_button .icon {
    display: inline-block;
    height: 1.625rem;
    margin-right: 0.75em;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 1.625rem;
  }
  .steuernews_preview .entry .fake_button .text {
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .steuernews_preview .image {
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.25);
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0 0 0 1.5em;
    -webkit-box-ordinal-group: 2;
            order: 1;
    position: relative;
    z-index: 5;
  }
  .steuernews_preview .image img {
    margin-right: 1.5em;
  }
  .steuernews_preview .teaser_text {
    margin: 2.25em 3em 0 1.5em;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .teaser_text p {
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues {
    background: #195D93;
    color: #fff;
    padding: 1.5em;
    margin-bottom: 1.5em;
  }
  .steuernews_issues.news_sidebar ul li {
    flex-basis: 50%;
    max-width: 50%;
  }
  .steuernews_issues h2 {
    color: #fff;
    padding: 0;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    flex-basis: 25%;
    max-width: 25%;
    padding-left: 0;
  }
  .steuernews_issues a {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.5em;
    text-decoration: none;
  }
  .steuernews_issues a:hover,
  .steuernews_issues a.active {
    text-decoration: underline;
  }
  .steuernews_issues a .icon {
    display: inline-block;
    fill: #fff;
    flex-shrink: 0;
    height: 1.25rem;
    margin-right: 0.375em;
    width: 0.9375rem;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    background: #195D93;
    color: #fff;
    padding: 1.5em;
    margin-bottom: 1.5em;
  }
  .steuernews_current_issue h2 {
    color: #fff;
    padding: 0;
  }
  .steuernews_current_issue a {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.5em;
    text-decoration: none;
  }
  .steuernews_current_issue a:hover,
  .steuernews_current_issue a.active {
    text-decoration: underline;
  }
  .steuernews_current_issue a .icon {
    display: inline-block;
    fill: #fff;
    flex-shrink: 0;
    height: 1.25rem;
    margin-right: 0.375em;
    width: 0.9375rem;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.5;
    padding: 0 1.5em;
  }
  .steuernews_contact_person {
    background: #EFEFEF;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #195D93;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
}
@media screen and (min-width: 30rem) {
  .author_hor {
    display: inline-block !important;
  }
}
@media screen and (min-width: 61.875rem) {
  .author_hor {
    margin-top: 0.625rem;
    top: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    width: calc(9.375rem + 7.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    width: 16.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner {
    display: -webkit-box;
    display: flex;
    padding: 1.5em;
  }
}
@media screen and (min-width: 61.875rem) {
  .cookie_banner {
    padding: 1.8em;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner .text_wrapper {
    flex-basis: 75%;
    max-width: 75%;
  }
}
@media screen and (min-width: 61.875rem) {
  .cookie_banner .text_wrapper {
    flex-basis: 85%;
    max-width: 85%;
  }
  .cookie_banner .text_wrapper .zusatz {
    display: inline;
  }
  .cookie_banner .text_wrapper .headline {
    display: inline-block;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner .link_wrapper {
    display: -webkit-box;
    display: flex;
    flex-basis: 25%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 0;
    max-width: 25%;
    text-align: center;
  }
}
@media screen and (min-width: 61.875rem) {
  .cookie_banner .link_wrapper {
    flex-basis: 15%;
    max-width: 15%;
  }
}
@media screen and (min-width: 20rem) {
  .cookie_banner .close > span {
    min-height: calc(2.5rem + 0 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.5rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .button_wrapper {
    margin-left: auto;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (max-width: 61.8125rem) {
  .aside {
    margin-top: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  footer {
    margin-top: calc(3em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer {
    margin-top: 6em;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_company_logo {
    width: calc(9.375rem + 7.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_company_logo {
    width: 16.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_top_bar {
    -webkit-box-align: center;
            align-items: center;
    border-bottom: 0.125rem solid #C2C2C2;
    display: -webkit-box;
    display: flex;
    padding: 0.6em 0;
  }
}
@media screen and (min-width: 30rem) {
  .header_top_bar .call_mail {
    top: 2.1875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_top_bar .call_mail {
    display: -webkit-box;
    display: flex;
    left: inherit;
    position: relative;
    top: inherit;
  }
}
@media screen and (min-width: 30rem) {
  .header_top_bar .call_mail a:first-child {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 43.75rem) {
  .header_top_bar .call_mail a:first-child {
    margin-bottom: 2.1em;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_top_bar .call_mail a:first-child {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 43.75rem) {
  .header_top_bar .call_mail a .text {
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  .header_top_bar .call_mail a .text:before {
    background: #195D93;
    bottom: 0;
    content: "";
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: right 300ms ease-out;
    transition: right 300ms ease-out;
    z-index: -1;
  }
}
@media screen and (min-width: 20rem) {
  .header_logo_nav {
    height: calc(7.5em + 3.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_logo_nav {
    height: 11.25em;
  }
}
@media screen and (min-width: 30rem) {
  .header_logo_nav {
    padding: 1.5em 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_logo_nav {
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 30rem) {
  .header_logo_nav .company_logo {
    margin-top: 0;
  }
}
@media screen and (max-width: 61.8125rem) {
  .header_logo_nav .container {
    position: relative;
  }
}
@media screen and (min-width: 61.875rem) {
  .header_picture {
    margin-bottom: 5rem;
    margin-right: calc(-50vw + 100%);
  }
  .startpage .header_picture {
    margin-bottom: 3.75rem;
    margin-right: calc(-58.33333333vw + 100%);
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button .text {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .u_online_benefits_flow .benefits_button:after {
    width: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .content_bg_image_container {
    margin-right: calc(-91.66666667vw + 100%);
  }
}
@media screen and (min-width: 160rem) {
  .content_bg_image_container.full_width {
    margin-left: calc(50% -  160rem / 2);
    margin-right: calc(50% -  160rem / 2);
  }
}
@media screen and (min-width: 43.75rem) {
  .toggle_offcanvas_menu {
    margin-top: 0.75em;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 43.75rem) {
  .toggle_offcanvas_menu label .text {
    display: inline-block;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper {
    margin-bottom: 6em;
  }
}
@media screen and (max-width: 61.8125rem) {
  .image_wrapper .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .sitetitle_wrapper:before {
    height: 100%;
    left: 100%;
    top: 0;
    width: 65%;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .sitetitle_wrapper:after {
    height: 100%;
    right: 100%;
    top: 0;
    width: 100%;
  }
}
@media screen and (min-width: 61.875rem) and (max-width: 74.9375rem) {
  .image_wrapper .sitetitle_wrapper .text_wrapper h1 {
    font-size: 210%;
  }
}
@media screen and (min-width: 35rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    max-width: 90%;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    font-size: 270%;
    max-width: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    margin-top: 8%;
  }
}
@media screen and (min-width: 67.5rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    max-width: 72%;
  }
}
@media screen and (min-width: 75.0625rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    font-size: 340%;
    max-width: 82%;
  }
}
@media screen and (min-width: 81.25rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    max-width: 75%;
  }
}
@media screen and (min-width: 89.125rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    max-width: 70%;
  }
}
@media screen and (min-width: 99.375rem) {
  .image_wrapper .sitetitle_wrapper .slogan {
    max-width: 65%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_search_box {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 61.875rem) {
  form[role="search"] {
    display: -webkit-box;
    display: flex;
    max-width: 16.875rem;
    margin-left: auto;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.5rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 3.125rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .first_name {
    font-size: calc(1.125rem + 0.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .first_name {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview .entry .family_name {
    font-size: calc(1.25rem + 0.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry .family_name {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide .description {
    padding: 0 4.5em 0 3em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .control_slides {
    top: 50%;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview {
    margin-bottom: calc(1.5em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview {
    margin-bottom: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  aside .teaser_overview .teaser {
    margin-bottom: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  aside .teaser_overview .teaser {
    margin-bottom: 3em;
  }
}
@media screen and (min-width: 20rem) {
  aside .teaser_overview .teaser .title {
    font-size: calc(1.0625rem + 0.0625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  aside .teaser_overview .teaser .title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview.startpage_news_overview {
    margin-top: calc(1.5em + 7.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview.startpage_news_overview {
    margin-top: 9em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .headline {
    font-size: calc(1.25rem + 0.3125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .headline {
    font-size: 1.5625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .overview_title {
    margin: 0 auto 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry {
    padding-bottom: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry {
    padding-bottom: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry {
    padding-top: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry {
    padding-top: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry:after {
    width: calc(1.5em + 0.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry:after {
    width: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry .fake_button {
    margin-top: calc(1.5em + 3 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry .fake_button {
    margin-top: 4.5em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues {
    margin-bottom: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues {
    margin-bottom: 3em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_current_issue {
    margin-bottom: calc(1.5em + 1.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_current_issue {
    margin-bottom: 3em;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.75em;
    max-width: 20rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 400;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .icon {
    display: none !important;
  }
  .qr_code_print {
    border-top: 0.125rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
